<template>
  <div style="height: 100%;background: #ebedf0;">
    <div class="biao_ti">
      <van-icon @click="back" class="fanhui" name="arrow-left" />
      <span
        style="vertical-align: middle"
        @click.stop="qiehuan"
      >{{chexin?chexin.carBrand+ '-' +chexin.carModel:''}}</span>
      <van-icon style="vertical-align: middle;margin-left: 2px;" name="arrow-down" />
    </div>
    <div style="padding-top:46px;position: relative;background: #ebedf0;">
      <div class="beijin_h"></div>
      <div class="xuanze">
        <div
          class="liebiao"
          v-for="(item,idx) in constd"
          @click="click_name(idx)"
          :key="idx"
          :class="isActive== idx ? 'isActive' : '' "
        >{{item.name}}({{item.cost}}/{{item.count}})</div>
      </div>
      <div class="shanpin_lis">
        <div class="shanb_biao" v-for="(item,index) in list" :key="index">
          <div @click="dianji(item)" class="ccd">
            <div class="shanb_h1">
              <van-checkbox
                icon-size="18px"
                class="left chek"
                v-model="item.checked"
                checked-color="#DF3D48"
              >
                <span>{{item.serviceName}}</span>
              </van-checkbox>
              <div class="right" v-if="!item.checked">
                <div class="imgbj" v-if="item.total > item.totalzhe">
                  <div class="jia_ding">
                    <div class="left" style="width: 58%;text-align: center;">
                      <span style="font-size: 12px;">￥</span>
                      <span style="font-size: 14px;">{{item.totalzhe?item.totalzhe.toFixed(0):''}}</span>
                    </div>
                    <div class="left yhuis" style="font-size: 12px;">
                      <div>已优惠</div>
                      <div>￥{{item.total - item.totalzhe}}</div>
                    </div>
                  </div>
                  <img class="jiabeijin" src="../../assets/img/jiabeijin.png" alt />
                </div>
                <span class="right jiner" v-else>
                  <span style="font-size: 12px;">￥</span>
                  <span>{{item.totalzhe?item.totalzhe.toFixed(0):''}}</span>
                </span>
                <!-- <del class="right jiner2" v-if="!item.checked">
                <span v-if="item.discount != 10">￥{{item.total?item.total.toFixed(0):''}}</span>
                </del>-->
              </div>
              <span class="right jiner" v-else>
                <!-- <span style="color: #df3447;margin-right: 8px;" v-if="!item.checked2">优惠券</span> -->
                <!-- <span @click.stop="clickWrapper(item)" v-if="!item.checked2">更换</span>
                <span v-else @click.stop="clickWrapper(item)">保存</span>-->
              </span>
            </div>
            <!-- 50000km或12个月/次 -->
            <div class="shanb_h2">
              <span v-if="!item.checked && item.recommend">推荐理由：{{item.recommend}}</span>
            </div>
            <div class="jinji" v-if="!item.checked && item.mallProduceVOMap">
              <span class="left">含：</span>
              <div class="left hanne">
                <span v-for="(it, ik, idxk) in item.mallProduceVOMap" :key="idxk">
                  <span
                    class="hantes"
                    v-for="(its,ikdx) in it"
                    :key="ikdx"
                  >{{its.classify}}×{{its.count}}</span>
                </span>
              </div>
            </div>
          </div>
          <div v-if="item.checked">
            <div class="list_fuwu" v-for="(val, key, idx) in item.mallProduceVOMap" :key="idx">
              <div
                v-for="(citem,inndx) in val"
                :key="inndx"
                style="overflow: hidden;"
                @click="toxiangqin(citem.id)"
              >
                <div style="overflow: hidden;">
                  <div class="left mian_a">
                    <img
                      class="order_img"
                      v-if="citem.coverImg1"
                      :src="citem.coverImg1"
                      @error="imgError(citem)"
                      alt
                    />
                    <img class="order_img" v-else src="../../assets/img/mendian.png" alt />
                  </div>
                  <div class="left mian_b">
                    <div class="lis_ha">
                      <span>{{citem.brand}}</span>
                      <span v-if="citem.model">/{{citem.model}}</span>
                      <span style="margin-left: 5px;">{{citem.partName}}</span>
                      <span v-if="citem.arr2" style="margin-left: 5px;">{{citem.arr2}}</span>
                      <span v-if="citem.arr3" style="margin-left: 5px;">{{citem.arr3}}</span>
                      <span v-if="key == '发动机油'" style="margin-left: 5px;">{{citem.unit}}</span>
                    </div>
                    <div class="lis_hb">
                      <span
                        v-if="citem.arr1"
                        style="color:#d16e22;border-color:#d16e22;"
                      >{{citem.arr1}}</span>
                      <span v-if="citem.arr3">{{citem.arr3}}</span>
                      <span v-if="citem.unit">{{citem.unit}}</span>
                      <span v-if="citem.title2">{{citem.title2}}</span>
                    </div>
                    <div class="lis_hc">
                      <span class="left jiner_c">
                        <span style="font-size: 9px;">￥</span>
                        <span
                          class="c_jiage"
                        >{{citem.activityPrice?citem.activityPrice.toFixed(0):'0'}}</span>
                      </span>
                      <div class="coot" v-if="key == '发动机油' && val.length >1">
                        <span class="left jian" @click.stop="jian(citem,val,inndx)"></span>
                        <span class="left shulian" style="color:#666;">x{{citem.count}}</span>
                        <span class="left jia" @click.stop="jia(citem)"></span>
                      </div>
                      <div class="coot" v-else>
                        <span class="left" style="width: 20px;height: 20px;"></span>
                        <span class="left shulian" style="color:#666;">x{{citem.count}}</span>
                        <span class="left" style="width: 20px;height: 20px;"></span>
                      </div>
                      <div class="c_gen" @click.stop="qugenhuan(citem,key,item.id)">更换</div>
                    </div>
                  </div>
                </div>
                <div class="cankao" v-if="key == '发动机油' && inndx == val.length-1">
                  <img class="liangdeng" src="../../assets/img/liangdeng.png" alt />
                  <span style="vertical-align: middle;margin-left: 3px;">机油参考用量:{{citem.dataTips}}</span>
                  <span
                    class="cantianjia"
                    v-if="val.length == 1"
                    @click.stop="jiyoutianjia(citem,val)"
                  >添加</span>
                </div>
                <!-- <div class="left mian_b2" v-else @click.stop>
                  <van-stepper class="left" v-model="citem.count" @change="heji" />
                  <div
                    class="right"
                    v-if="citem.attribute == 1"
                    @click.stop="genhuan(citem,key,item.id)"
                  >换产品</div>
                  <div class="right" v-else @click.stop="huan(citem,key,item.id)">换品牌</div>
                  <div class="right sscc" @click.stop="shanchu(item.mallProduceVOMap,key)">删除</div>
                </div>-->
              </div>
            </div>
            <div class="list_fuwu">
              <div class="left mian_a">
                <img src="../../assets/img/gongshi.png" class="order_img" alt />
              </div>
              <div class="left mian_b">
                <div class="lis_ha">工时费</div>
                <div class="lis_hc">
                  <span class="left jiner_c">
                    <span style="font-size: 9px;">￥</span>
                    <span class="c_jiage">{{item.sellPrice}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clear"></div>
        <!--  -->
      </div>
      <van-empty v-if="list.length < 1" description="暂无数据~" />
    </div>
    <van-popup v-model="tan" position="bottom" :style="{ height: '30%' }">
      <div class="cgengen">更换品牌</div>
      <div
        class="ccname"
        v-for="(item,idd) in pinpaiList"
        :key="idd"
        @click="huanpinpai(item)"
      >{{item}}</div>
    </van-popup>
    <div class="bott">
      <div class="left bott_a">
        <img class="kefu" src="../../assets/img/kefu.png" alt />
        <p class="zaixian">在线客服</p>
      </div>
      <div class="bott_c right" @click="jiesuan">去结算</div>
      <div class="bott_b right">
        <div>
          <span class="heji">{{xiang}}项合计：</span>
          <span class="danwei">￥</span>
          <span class="monede">{{zhehou}}</span>
          <del class="zhe">￥{{zongjia}}</del>
        </div>
        <div class="anzhuan">不含安装费</div>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import { Dialog } from "vant";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  name: "maintain",
  components: {},
  data() {
    return {
      chexin: {},
      shangjia: {},
      list: [],
      constd: [],
      cotlist: [],
      pinpaiList: [],
      value: 1,
      show: true,
      tan: false,
      fuwu: 0,
      zongjia: 0, //合计价格
      zhehou: 0, //合计折后价格
      zongheji: 0, //合计折后的配件和服务费
      xiang: 0, //一共几项
      isActive: 0,
      name: this.$route.query.name,
      cid: "",
      classify: "",
      special: "",
      huanitem: {}
    };
  },
  methods: {
    imgError(item) {
      item.coverImg1 = require("../../assets/img/mendian.png");
    },
    //减数量
    jian(citem, val, idx) {
      if (citem.count <= 1) {
        Dialog.confirm({
          title: "",
          message: "将删除此款商品",
          confirmButtonText: "删除",
          confirmButtonColor: "#ee0a24",
          cancelButtonColor: "#999999"
        })
          .then(() => {
            val.splice(idx, 1);
          })
          .catch(() => {
            // on cancel
          });
      } else {
        citem.count--;
        this.heji();
      }
    },
    //去跟换或者换产品
    qugenhuan(val, key, cid) {
      console.log(val);
      if (val.attribute == 1) {
        sessionStorage.setItem("cid", cid);
        sessionStorage.setItem("valid", val.id);
        sessionStorage.setItem("count", val.count);
        sessionStorage.setItem("data", JSON.stringify(val));
        this.$router.push({
          path: "change_product",
          query: {
            classify: key,
            tires: val.special,
            unit: val.unit,
            dataTips: val.dataTips,
            rule: val.rule,
            keyWord: val.keyWord
          }
        });
      } else {
        this.cid = cid;
        this.special = val.special;
        // console.log(val)
        this.huanitem = val;
        this.classify = key;
        userModel
          .partBrand({ classify: key })
          .then(e => {
            this.pinpaiList = e.data;
          })
          .catch();
        this.tan = true;
      }
    },
    //添加机油1l-4l
    jiyoutianjia(item, val) {
      console.log(item);
      var data = {
        gid: this.shangjia.id,
        dataTips: item.dataTips,
        norm: item.norm,
        special: item.special,
        type: item.unit,
        brand: item.brand
      };
      userModel
        .jiyouoil(data)
        .then(e => {
          if (e.data.norm != null) {
            var norm = e.data.norm.split("/");
            let arr = norm[1].split("");
            arr.splice(2, 0, "/");
            norm[1] = arr.join("");
            e.data.arr1 = norm[0];
            e.data.arr2 = norm[1];
            e.data.arr3 = norm[2];
          }
          console.log(e.data);
          val.push(e.data);
        })
        .catch();
    },
    // //删除商品
    // shanchu(val, key) {
    //   if (Object.keys(val).length <= 1) {
    //     this.$toast("最少保留一个！");
    //     return;
    //   }
    //   this.$delete(val, key);
    //   this.heji();
    // },
    //加数量
    jia(citem) {
      citem.count++;
      this.heji();
    },
    //跳转详情
    toxiangqin(id) {
      if (id != null) {
        this.$router.push({
          path: "change_details",
          query: {
            id: id
          }
        });
      }
    },
    // 换产品
    huan(val, key, cid) {
      this.cid = cid;
      this.special = val.special;
      // console.log(val)
      this.huanitem = val;
      this.classify = key;
      userModel
        .partBrand({ classify: key })
        .then(e => {
          this.pinpaiList = e.data;
        })
        .catch();
      this.tan = true;
    },
    huanpinpai(item) {
      let data = {
        classify: this.classify,
        gid: this.shangjia.id,
        partBrand: item,
        special: this.huanitem.special
      };
      userModel
        .genhuanlist(data)
        .then(e => {
          // this.pinpaiList = e.data;
          // console.log(this.list);
          console.log(this.cid);
          this.list.map((c, i) => {
            if (c.id == this.cid) {
              for (var i in c.mallProduceVOMap) {
                for (var j in c.mallProduceVOMap[i]) {
                  if (c.mallProduceVOMap[i][j].special == this.special) {
                    c.mallProduceVOMap[i][j] = e.data;
                  }
                }
              }
            }
          });
          console.log(this.list);
          this.heji();
          this.tan = false;
        })
        .catch();
    },
    qiehuan() {
      // this.$router.push({
      //   name: "model"
      // });
      this.$router.push({
        path: "model",
        query: {
          isok: "true"
        }
      });
    },
    jiesuan() {
      var services = [];
      var goods = [];
      var applyItem = "";
      if (this.cotlist.airConditioner.length > 0) {
        //空调系统
        this.cotlist.airConditioner.map((c, i) => {
          if (c.checked == true) {
            var str = "";
            for (var i in c.mallProduceVOMap) {
              for (var j in c.mallProduceVOMap[i]) {
                var peijian = {
                  partBrand: c.mallProduceVOMap[i][j].brand,
                  brand: c.mallProduceVOMap[i][j].brand,
                  partName: j,
                  attrImg: c.mallProduceVOMap[i][j].coverImg1,
                  disc: c.discount,
                  discStatus: 2,
                  goodsType: 1,
                  norm: c.mallProduceVOMap[i][j].norm,
                  model: c.mallProduceVOMap[i][j].model,
                  goodsId: c.mallProduceVOMap[i][j].id,
                  goodsNum: c.mallProduceVOMap[i][j].count,
                  price: c.mallProduceVOMap[i][j].activityPrice,
                  title: c.mallProduceVOMap[i][j].partName,
                  goodsIds: c.id
                };
                str += c.mallProduceVOMap[i][j].id + ",";
                goods.push(peijian);
              }
            }
            var fuwu = {
              disc: c.discount, //商品折扣
              discStatus: 2, //打折状态
              goodsNum: 1, //购买数量
              goodsId: c.id,
              price: c.sellPrice, //商品价格
              title: c.serviceName,
              goodsIds: str,
              appointmentId:c.appointmentId
            };
            services.push(fuwu);
            applyItem += c.serviceName + ",";
          }
        });
      }
      if (this.cotlist.braking.length > 0) {
        //制动系统
        this.cotlist.braking.map((c, i) => {
          if (c.checked == true) {
            var str = "";
            for (var i in c.mallProduceVOMap) {
              for (var j in c.mallProduceVOMap[i]) {
                var peijian = {
                  partBrand: c.mallProduceVOMap[i][j].brand,
                  brand: c.mallProduceVOMap[i][j].brand,
                  partName: j,
                  attrImg: c.mallProduceVOMap[i][j].coverImg1,
                  disc: c.discount,
                  discStatus: 2,
                  goodsType: 1,

                  norm: c.mallProduceVOMap[i][j].norm,
                  model: c.mallProduceVOMap[i][j].model,
                  goodsId: c.mallProduceVOMap[i][j].id,
                  goodsNum: c.mallProduceVOMap[i][j].count,
                  price: c.mallProduceVOMap[i][j].activityPrice,
                  title: c.mallProduceVOMap[i][j].partName,
                  goodsIds: c.id
                };
                str += c.mallProduceVOMap[i][j].id + ",";
                goods.push(peijian);
              }
            }
            var fuwu = {
              disc: c.discount, //商品折扣
              discStatus: 2, //打折状态
              goodsNum: 1, //购买数量
              goodsId: c.id,
              price: c.sellPrice, //商品价格
              title: c.serviceName,
              goodsIds: str,
              appointmentId:c.appointmentId
            };
            services.push(fuwu);
            applyItem += c.serviceName + ",";
          }
        });
      }
      if (this.cotlist.curing.length > 0) {
        //基础养护
        this.cotlist.curing.map((c, i) => {
          if (c.checked == true) {
            var str = "";
            for (var i in c.mallProduceVOMap) {
              for (var j in c.mallProduceVOMap[i]) {
                var peijian = {
                  partBrand: c.mallProduceVOMap[i][j].brand,
                  brand: c.mallProduceVOMap[i][j].brand,
                  partName: j,
                  attrImg: c.mallProduceVOMap[i][j].coverImg1,
                  disc: c.discount,
                  discStatus: 2,
                  goodsType: 1,
                  norm: c.mallProduceVOMap[i][j].norm,
                  model: c.mallProduceVOMap[i][j].model,
                  goodsId: c.mallProduceVOMap[i][j].id,
                  goodsNum: c.mallProduceVOMap[i][j].count,
                  price: c.mallProduceVOMap[i][j].activityPrice,
                  title: c.mallProduceVOMap[i][j].partName,
                  goodsIds: c.id
                };
                str += c.mallProduceVOMap[i][j].id + ",";
                goods.push(peijian);
              }
            }
            var fuwu = {
              disc: c.discount, //商品折扣
              discStatus: 2, //打折状态
              goodsNum: 1, //购买数量
              goodsId: c.id,
              price: c.sellPrice, //商品价格
              title: c.serviceName,
              goodsIds: str,
              appointmentId:c.appointmentId
            };
            services.push(fuwu);
            applyItem += c.serviceName + ",";
          }
        });
      }
      if (this.cotlist.engine.length > 0) {
        //引擎深度
        this.cotlist.engine.map((c, i) => {
          if (c.checked == true) {
            var str = "";
            for (var i in c.mallProduceVOMap) {
              for (var j in c.mallProduceVOMap[i]) {
                var peijian = {
                  partBrand: c.mallProduceVOMap[i][j].brand,
                  brand: c.mallProduceVOMap[i][j].brand,
                  partName: j,
                  attrImg: c.mallProduceVOMap[i][j].coverImg1,
                  disc: c.discount,
                  discStatus: 2,
                  goodsType: 1,
                  norm: c.mallProduceVOMap[i][j].norm,
                  model: c.mallProduceVOMap[i][j].model,
                  goodsId: c.mallProduceVOMap[i][j].id,
                  goodsNum: c.mallProduceVOMap[i][j].count,
                  price: c.mallProduceVOMap[i][j].activityPrice,
                  title: c.mallProduceVOMap[i][j].partName,
                  goodsIds: c.id
                };
                str += c.mallProduceVOMap[i][j].id + ",";
                goods.push(peijian);
              }
            }
            var fuwu = {
              disc: c.discount, //商品折扣
              discStatus: 2, //打折状态
              goodsNum: 1, //购买数量
              goodsId: c.id,
              price: c.sellPrice, //商品价格
              title: c.serviceName,
              goodsIds: str,
              appointmentId:c.appointmentId
            };
            services.push(fuwu);
            applyItem += c.serviceName + ",";
          }
        });
      }
      if (this.cotlist.inspect.length > 0) {
        //检查替换
        this.cotlist.inspect.map((c, i) => {
          if (c.checked == true) {
            var str = "";
            for (var i in c.mallProduceVOMap) {
              for (var j in c.mallProduceVOMap[i]) {
                var peijian = {
                  partBrand: c.mallProduceVOMap[i][j].brand,
                  brand: c.mallProduceVOMap[i][j].brand,
                  partName: j,
                  attrImg: c.mallProduceVOMap[i][j].coverImg1,
                  disc: c.discount,
                  discStatus: 2,
                  goodsType: 1,
                  norm: c.mallProduceVOMap[i][j].norm,
                  model: c.mallProduceVOMap[i][j].model,
                  goodsId: c.mallProduceVOMap[i][j].id,
                  goodsNum: c.mallProduceVOMap[i][j].count,
                  price: c.mallProduceVOMap[i][j].activityPrice,
                  title: c.mallProduceVOMap[i][j].partName,
                  goodsIds: c.id
                };
                str += c.mallProduceVOMap[i][j].id + ",";
                goods.push(peijian);
              }
            }
            var fuwu = {
              disc: c.discount, //商品折扣
              discStatus: 2, //打折状态
              goodsNum: 1, //购买数量
              goodsId: c.id,
              price: c.sellPrice, //商品价格
              title: c.serviceName,
              goodsIds: str,
              appointmentId:c.appointmentId
            };
            services.push(fuwu);
            applyItem += c.serviceName + ",";
          }
        });
      }
      if (this.cotlist.refrigeration.length > 0) {
        //冷却系统
        this.cotlist.refrigeration.map((c, i) => {
          if (c.checked == true) {
            var str = "";
            for (var i in c.mallProduceVOMap) {
              for (var j in c.mallProduceVOMap[i]) {
                var peijian = {
                  partBrand: c.mallProduceVOMap[i][j].brand,
                  brand: c.mallProduceVOMap[i][j].brand,
                  partName: j,
                  attrImg: c.mallProduceVOMap[i][j].coverImg1,
                  disc: c.discount,
                  discStatus: 2,
                  goodsType: 1,
                  norm: c.mallProduceVOMap[i][j].norm,
                  model: c.mallProduceVOMap[i][j].model,
                  goodsId: c.mallProduceVOMap[i][j].id,
                  goodsNum: c.mallProduceVOMap[i][j].count,
                  price: c.mallProduceVOMap[i][j].activityPrice,
                  title: c.mallProduceVOMap[i][j].partName,
                  goodsIds: c.id
                };
                str += c.mallProduceVOMap[i][j].id + ",";
                goods.push(peijian);
              }
            }
            var fuwu = {
              disc: c.discount, //商品折扣
              discStatus: 2, //打折状态
              goodsNum: 1, //购买数量
              goodsId: c.id,
              price: c.sellPrice, //商品价格
              title: c.serviceName,
              goodsIds: str,
              appointmentId:c.appointmentId
            };
            services.push(fuwu);
            applyItem += c.serviceName + ",";
          }
        });
      }
      if (services.length < 1) {
        this.$toast("请先选择保养类型！");
        return;
      }
      var xiadan = {
        services: services,
        goods: goods,
        type: 6,
        orderTotal: this.zongheji,
        fuwu: this.fuwu,
        applyItem: applyItem
      };
      sessionStorage.removeItem("xiadan");
      sessionStorage.setItem("xiadan", JSON.stringify(xiadan));
      this.$router.push({
        path: "place_order",
        query: {
          baoyang: "baoyang"
        }
      });

      console.log(xiadan);
    },
    dianji(item) {
      item.checked = !item.checked;
      var shu = 0;
      // this.constd.map((c, i) => {
      this.list.map((d, i) => {
        if (d.checked == true) {
          shu += 1;
        }
      });

      if (item.itemize == 1) {
        this.constd[0].cost = shu;
      } else if (item.itemize == 2) {
        this.constd[1].cost = shu;
      } else if (item.itemize == 3) {
        this.constd[2].cost = shu;
      } else if (item.itemize == 4) {
        this.constd[3].cost = shu;
      } else if (item.itemize == 5) {
        this.constd[4].cost = shu;
      } else if (item.itemize == 6) {
        this.constd[5].cost = shu;
      }
      this.xiang = 0;
      this.constd.map((d, i) => {
        this.xiang += d.cost;
      });

      // if()
      // });
      this.jishuan(item);
      this.heji();
      this.$forceUpdate();
      // console.log(item);
    },
    heji() {
      var heji1 = 0;
      var heji2 = 0;
      var heji3 = 0;
      var heji4 = 0;
      var heji5 = 0;
      var heji6 = 0;
      var fuwu = 0;
      var zhefuwu = 0;
      if (this.cotlist.airConditioner.length > 0) {
        //空调系统
        this.cotlist.airConditioner.map((c, i) => {
          if (c.checked == true) {
            fuwu += c.sellPrice;
            if (c.discount == null) {
              zhefuwu += c.sellPrice;
            } else {
              zhefuwu += c.sellPrice * (c.discount * 0.1);
            }
            for (var i in c.mallProduceVOMap) {
              for (var j in c.mallProduceVOMap[i]) {
                heji1 +=
                  c.mallProduceVOMap[i][j].activityPrice *
                  c.mallProduceVOMap[i][j].count;
              }
            }
          }
        });
      }
      if (this.cotlist.braking.length > 0) {
        //制动系统
        this.cotlist.braking.map((c, i) => {
          if (c.checked == true) {
            fuwu += c.sellPrice;
            if (c.discount == null) {
              zhefuwu += c.sellPrice;
            } else {
              zhefuwu += c.sellPrice * (c.discount * 0.1);
            }
            for (var i in c.mallProduceVOMap) {
              for (var j in c.mallProduceVOMap[i]) {
                heji1 +=
                  c.mallProduceVOMap[i][j].activityPrice *
                  c.mallProduceVOMap[i][j].count;
              }
            }
          }
        });
      }
      if (this.cotlist.curing.length > 0) {
        //基础养护
        this.cotlist.curing.map((c, i) => {
          if (c.checked == true) {
            fuwu += c.sellPrice;
            if (c.discount == null) {
              zhefuwu += c.sellPrice;
            } else {
              zhefuwu += c.sellPrice * (c.discount * 0.1);
            }
            for (var i in c.mallProduceVOMap) {
              for (var j in c.mallProduceVOMap[i]) {
                heji1 +=
                  c.mallProduceVOMap[i][j].activityPrice *
                  c.mallProduceVOMap[i][j].count;
              }
            }
          }
        });
        // console.log(heji1)
      }
      if (this.cotlist.engine.length > 0) {
        //引擎深度
        this.cotlist.engine.map((c, i) => {
          if (c.checked == true) {
            fuwu += c.sellPrice;
            if (c.discount == null) {
              zhefuwu += c.sellPrice;
            } else {
              zhefuwu += c.sellPrice * (c.discount * 0.1);
            }
            for (var i in c.mallProduceVOMap) {
              for (var j in c.mallProduceVOMap[i]) {
                heji1 +=
                  c.mallProduceVOMap[i][j].activityPrice *
                  c.mallProduceVOMap[i][j].count;
              }
            }
          }
        });
      }
      if (this.cotlist.inspect.length > 0) {
        //检查替换
        this.cotlist.inspect.map((c, i) => {
          if (c.checked == true) {
            fuwu += c.sellPrice;
            if (c.discount == null) {
              zhefuwu += c.sellPrice;
            } else {
              zhefuwu += c.sellPrice * (c.discount * 0.1);
            }
            for (var i in c.mallProduceVOMap) {
              for (var j in c.mallProduceVOMap[i]) {
                heji1 +=
                  c.mallProduceVOMap[i][j].activityPrice *
                  c.mallProduceVOMap[i][j].count;
              }
            }
          }
        });
      }
      if (this.cotlist.refrigeration.length > 0) {
        //冷却系统
        this.cotlist.refrigeration.map((c, i) => {
          if (c.checked == true) {
            fuwu += c.sellPrice;
            if (c.discount == null) {
              zhefuwu += c.sellPrice;
            } else {
              zhefuwu += c.sellPrice * (c.discount * 0.1);
            }

            for (var i in c.mallProduceVOMap) {
              for (var j in c.mallProduceVOMap[i]) {
                heji1 +=
                  c.mallProduceVOMap[i][j].activityPrice *
                  c.mallProduceVOMap[i][j].count;
              }
            }
          }
        });
      }
      // console.log(fuwu);
      // console.log(zhefuwu);
      this.zongjia = (
        heji1 +
        heji2 +
        heji3 +
        heji4 +
        heji5 +
        heji6 +
        fuwu
      ).toFixed(0);
      this.zhehou = (
        heji1 +
        heji2 +
        heji3 +
        heji4 +
        heji5 +
        heji6 +
        zhefuwu
      ).toFixed(0);
      var zhe = Number(this.zhehou);
      this.fuwu = fuwu;
      // console.log(fuwu);
      // console.log(zhe);
      this.zongheji = zhe;
      // console.log(this.zongheji);
    },
    //更换商品
    genhuan(val, key, cid) {
      console.log(cid);
      sessionStorage.setItem("cid", cid);
      sessionStorage.setItem("valid", val.id);
      sessionStorage.setItem("count", val.count);
      sessionStorage.setItem("data", JSON.stringify(val));
      this.$router.push({
        path: "change_product",
        query: {
          classify: key,
          tires: val.special,
          unit: val.unit,
          dataTips: val.dataTips
        }
      });
    },

    clickWrapper(item) {
      item.checked2 = !item.checked2;
      console.log(item);
      this.jishuan(item);
    },
    jishuan(item) {
      var total = 0;
      for (var i in item.mallProduceVOMap) {
        for (var j in item.mallProduceVOMap[i]) {
          total +=
            item.mallProduceVOMap[i][j].activityPrice *
            item.mallProduceVOMap[i][j].count;
        }
      }
      if (item.discount == null) {
        var fuwu = item.sellPrice;
      } else {
        var fuwu = item.sellPrice * (item.discount * 0.1);
      }

      var heji = total + item.sellPrice;
      item.totalzhe = total + fuwu;
      item.total = heji;
    },
    click_name(idx) {
      if (idx == 0) {
        this.list = this.cotlist.curing;
      }
      if (idx == 1) {
        this.list = this.cotlist.airConditioner;
      }
      if (idx == 2) {
        this.list = this.cotlist.braking;
      }
      if (idx == 3) {
        this.list = this.cotlist.refrigeration;
      }
      if (idx == 4) {
        this.list = this.cotlist.engine;
      }
      if (idx == 5) {
        this.list = this.cotlist.inspect;
      }
      this.$forceUpdate();
      console.log(this.list);
      this.isActive = idx;
    },
    onLoad() {
      const data = {
        vinCode: this.chexin.carVin,
        carModel: this.chexin.carBrand,
        // carModel: "宝马",
        // vinCode: "LVGCJE233HG219386",
        gid: this.shangjia.id
        // gid: 223
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      userModel
        .yanhulist(data)
        .then(e => {
          loading.clear();

          this.cotlist = e.data;
          this.list = this.cotlist.curing;

          if (this.cotlist.airConditioner.length > 0) {
            //空调系统
            this.cotlist.airConditioner.map((c, i) => {
              var total = 0;
              if (c.serviceName == this.name) {
                this.$set(c, "checked", true);
                this.$set(c, "checked2", true);
                this.isActive = 1;
                this.list = this.cotlist.airConditioner;
              } else {
                this.$set(c, "checked", false);
                this.$set(c, "checked2", false);
              }

              for (var i in c.mallProduceVOMap) {
                for (var j in c.mallProduceVOMap[i]) {
                  total +=
                    c.mallProduceVOMap[i][j].activityPrice *
                    c.mallProduceVOMap[i][j].count;
                  if (c.mallProduceVOMap[i][j].norm != null) {
                    var str = c.mallProduceVOMap[i][j].norm.indexOf("/") != -1;

                    if (str == true) {
                      var norm = c.mallProduceVOMap[i][j].norm.split("/");
                      let arr = norm[1].split("");
                      arr.splice(2, 0, "/");
                      norm[1] = arr.join("");
                      c.mallProduceVOMap[i][j].arr1 = norm[0];
                      c.mallProduceVOMap[i][j].arr2 = norm[1];
                      c.mallProduceVOMap[i][j].arr3 = norm[2];
                    } else {
                      c.mallProduceVOMap[i][j].arr1 =
                        c.mallProduceVOMap[i][j].norm;
                    }
                  }
                }
              }

              if (c.discount == null) {
                var fuwu = c.sellPrice;
              } else {
                var fuwu = c.sellPrice * (c.discount * 0.1);
              }

              var heji = total + c.sellPrice;
              var totalzhe = total + fuwu;

              this.$set(c, "totalzhe", totalzhe);
              this.$set(c, "total", heji);
            });
          }
          if (this.cotlist.braking.length > 0) {
            //制动系统
            this.cotlist.braking.map((c, i) => {
              var total = 0;
              if (c.serviceName == this.name) {
                this.$set(c, "checked", true);
                this.$set(c, "checked2", true);
                this.isActive = 2;
                this.list = this.cotlist.braking;
              } else {
                this.$set(c, "checked", false);
                this.$set(c, "checked2", false);
              }
              for (var i in c.mallProduceVOMap) {
                for (var j in c.mallProduceVOMap[i]) {
                  total +=
                    c.mallProduceVOMap[i][j].activityPrice *
                    c.mallProduceVOMap[i][j].count;
                  if (c.mallProduceVOMap[i][j].norm != null) {
                    var str = c.mallProduceVOMap[i][j].norm.indexOf("/") != -1;
                    if (str == true) {
                      var norm = c.mallProduceVOMap[i][j].norm.split("/");
                      let arr = norm[1].split("");
                      arr.splice(2, 0, "/");
                      norm[1] = arr.join("");
                      c.mallProduceVOMap[i][j].arr1 = norm[0];
                      c.mallProduceVOMap[i][j].arr2 = norm[1];
                      c.mallProduceVOMap[i][j].arr3 = norm[2];
                    } else {
                      c.mallProduceVOMap[i][j].arr1 =
                        c.mallProduceVOMap[i][j].norm;
                    }
                  }
                }
              }
              if (c.discount == null) {
                var fuwu = c.sellPrice;
              } else {
                var fuwu = c.sellPrice * (c.discount * 0.1);
              }
              var heji = total + c.sellPrice;
              var totalzhe = total + fuwu;
              this.$set(c, "totalzhe", totalzhe);
              this.$set(c, "total", heji);
            });
          }
          if (this.cotlist.curing.length > 0) {
            // console.log(this.name);
            //基础养护
            this.cotlist.curing.map((c, i) => {
              var total = 0;
              if (c.serviceName == this.name) {
                this.$set(c, "checked", true);
                this.$set(c, "checked2", true);
                this.isActive = 0;
                this.list = this.cotlist.curing;
              } else {
                this.$set(c, "checked", false);
                this.$set(c, "checked2", false);
              }

              for (var i in c.mallProduceVOMap) {
                for (var j in c.mallProduceVOMap[i]) {
                  total +=
                    c.mallProduceVOMap[i][j].activityPrice *
                    c.mallProduceVOMap[i][j].count;
                  if (c.mallProduceVOMap[i][j].norm != null) {
                    var str = c.mallProduceVOMap[i][j].norm.indexOf("/") != -1;
                    if (str == true) {
                      var norm = c.mallProduceVOMap[i][j].norm.split("/");
                      let arr = norm[1].split("");
                      arr.splice(2, 0, "/");
                      norm[1] = arr.join("");
                      c.mallProduceVOMap[i][j].arr1 = norm[0];
                      c.mallProduceVOMap[i][j].arr2 = norm[1];
                      c.mallProduceVOMap[i][j].arr3 = norm[2];
                    } else {
                      c.mallProduceVOMap[i][j].arr1 =
                        c.mallProduceVOMap[i][j].norm;
                    }
                  }
                }
              }
              if (c.discount == null) {
                var fuwu = c.sellPrice;
              } else {
                var fuwu = c.sellPrice * (c.discount * 0.1);
              }

              var heji = total + c.sellPrice;
              var totalzhe = total + fuwu;
              // console.log(totalzhe);
              this.$set(c, "totalzhe", totalzhe);
              this.$set(c, "total", heji);
            });
          }
          if (this.cotlist.engine.length > 0) {
            //引擎深度
            this.cotlist.engine.map((c, i) => {
              var total = 0;
              if (c.serviceName == this.name) {
                this.$set(c, "checked", true);
                this.$set(c, "checked2", true);
                this.list = this.cotlist.engine;
                this.isActive = 4;
              } else {
                this.$set(c, "checked", false);
                this.$set(c, "checked2", false);
              }
              for (var i in c.mallProduceVOMap) {
                for (var j in c.mallProduceVOMap[i]) {
                  total +=
                    c.mallProduceVOMap[i][j].activityPrice *
                    c.mallProduceVOMap[i][j].count;
                  if (c.mallProduceVOMap[i][j].norm != null) {
                    var str = c.mallProduceVOMap[i][j].norm.indexOf("/") != -1;
                    if (str == true) {
                      var norm = c.mallProduceVOMap[i][j].norm.split("/");
                      let arr = norm[1].split("");
                      arr.splice(2, 0, "/");
                      norm[1] = arr.join("");
                      c.mallProduceVOMap[i][j].arr1 = norm[0];
                      c.mallProduceVOMap[i][j].arr2 = norm[1];
                      c.mallProduceVOMap[i][j].arr3 = norm[2];
                    } else {
                      c.mallProduceVOMap[i][j].arr1 =
                        c.mallProduceVOMap[i][j].norm;
                    }
                  }
                }
              }
              if (c.discount == null) {
                var fuwu = c.sellPrice;
              } else {
                var fuwu = c.sellPrice * (c.discount * 0.1);
              }
              var heji = total + c.sellPrice;
              var totalzhe = total + fuwu;
              this.$set(c, "totalzhe", totalzhe);
              this.$set(c, "total", heji);
            });
          }
          if (this.cotlist.inspect.length > 0) {
            //检查替换
            this.cotlist.inspect.map((c, i) => {
              var total = 0;
              if (c.serviceName == this.name) {
                this.$set(c, "checked", true);
                this.$set(c, "checked2", true);
                this.isActive = 5;
                this.list = this.cotlist.inspect;
              } else {
                this.$set(c, "checked", false);
                this.$set(c, "checked2", false);
              }
              for (var i in c.mallProduceVOMap) {
                for (var j in c.mallProduceVOMap[i]) {
                  total +=
                    c.mallProduceVOMap[i][j].activityPrice *
                    c.mallProduceVOMap[i][j].count;
                  if (c.mallProduceVOMap[i][j].norm != null) {
                    var str = c.mallProduceVOMap[i][j].norm.indexOf("/") != -1;
                    if (str == true) {
                      var norm = c.mallProduceVOMap[i][j].norm.split("/");
                      let arr = norm[1].split("");
                      arr.splice(2, 0, "/");
                      norm[1] = arr.join("");
                      c.mallProduceVOMap[i][j].arr1 = norm[0];
                      c.mallProduceVOMap[i][j].arr2 = norm[1];
                      c.mallProduceVOMap[i][j].arr3 = norm[2];
                    } else {
                      c.mallProduceVOMap[i][j].arr1 =
                        c.mallProduceVOMap[i][j].norm;
                    }
                  }
                }
              }
              if (c.discount == null) {
                var fuwu = c.sellPrice;
              } else {
                var fuwu = c.sellPrice * (c.discount * 0.1);
              }
              var heji = total + c.sellPrice;
              var totalzhe = total + fuwu;
              this.$set(c, "totalzhe", totalzhe);
              this.$set(c, "total", heji);
            });
          }
          if (this.cotlist.refrigeration.length > 0) {
            //冷却系统
            this.cotlist.refrigeration.map((c, i) => {
              var total = 0;
              if (c.serviceName == this.name) {
                this.$set(c, "checked", true);
                this.$set(c, "checked2", true);
                this.isActive = 3;
                this.list = this.cotlist.refrigeration;
              } else {
                this.$set(c, "checked", false);
                this.$set(c, "checked2", false);
              }
              for (var i in c.mallProduceVOMap) {
                for (var j in c.mallProduceVOMap[i]) {
                  total +=
                    c.mallProduceVOMap[i][j].activityPrice *
                    c.mallProduceVOMap[i][j].count;
                  if (c.mallProduceVOMap[i][j].norm != null) {
                    var str = c.mallProduceVOMap[i][j].norm.indexOf("/") != -1;
                    if (str == true) {
                      var norm = c.mallProduceVOMap[i][j].norm.split("/");
                      let arr = norm[1].split("");
                      arr.splice(2, 0, "/");
                      norm[1] = arr.join("");
                      c.mallProduceVOMap[i][j].arr1 = norm[0];
                      c.mallProduceVOMap[i][j].arr2 = norm[1];
                      c.mallProduceVOMap[i][j].arr3 = norm[2];
                    } else {
                      c.mallProduceVOMap[i][j].arr1 =
                        c.mallProduceVOMap[i][j].norm;
                    }
                  }
                }
              }
              if (c.discount == null) {
                var fuwu = c.sellPrice;
              } else {
                var fuwu = c.sellPrice * (c.discount * 0.1);
              }
              var heji = total + c.sellPrice;
              var totalzhe = total + fuwu;
              this.$set(c, "totalzhe", totalzhe);
              this.$set(c, "total", heji);
            });
          }
          console.log(this.cotlist);
          this.heji();
          this.$forceUpdate();
        })
        .catch(() => loading.clear());
    },
    // pushHistory() {
    //   //写入空白历史路径
    //   let state = {
    //     title: "title",
    //     url: "#"
    //   };
    //   window.history.pushState(state, state.title, state.url);
    // },

    // backziding() {
    //   this.pushHistory();
    //   window.addEventListener(
    //     "popstate",
    //     function(e) {
    //       location.href = "/user";
    //     },
    //     false
    //   );
    // },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      //   // query: {
      //   //   status: this.status
      //   // }
      // });
    },
    namelist() {
      const data = {
        gid: this.shangjia.id
      };

      userModel
        .count(data)
        .then(e => {
          this.constd = e.data;
          this.constd.map((c, i) => {
            this.$set(c, "cost", 0);
          });
          console.log(this.const);
        })
        .catch();
    }
  },
  created() {
    this.chexin = JSON.parse(sessionStorage.getItem("chexin"));
    this.shangjia = JSON.parse(sessionStorage.getItem("shangjia"));
    this.namelist();
    this.onLoad();

    this.isFirstEnter = true;
  },
  mounted() {
    this.chexin = JSON.parse(sessionStorage.getItem("chexin"));
  },
  activated() {
    // console.log(this.$route.meta);

    this.name = this.$route.query.name;
    //  console.log(this.name)
    if (this.$route.meta.isBack || this.isFirstEnter) {
      // this.backziding();
      this.chexin = JSON.parse(sessionStorage.getItem("chexin"));
      this.shangjia = JSON.parse(sessionStorage.getItem("shangjia"));
      // 如果isBack是false，表明需要获取新数据，否则就不再请求，直接使用缓存的数据
      this.namelist();
      this.onLoad();
      sessionStorage.removeItem("valid");
      sessionStorage.removeItem("cid");
      sessionStorage.removeItem("data");
      sessionStorage.removeItem("isok");
      sessionStorage.removeItem("isok2");
    } else {
      this.chexin = JSON.parse(sessionStorage.getItem("chexin"));
      if (sessionStorage.getItem("isok")) {
        // console.log("执行了");
        this.shangjia = JSON.parse(sessionStorage.getItem("shangjia"));
        this.list.map((c, i) => {
          console.log(c);
          if (c.id == sessionStorage.getItem("cid")) {
            for (var i in c.mallProduceVOMap) {
              for (var j in c.mallProduceVOMap[i]) {
                console.log(c.mallProduceVOMap[i][j].id);
                if (
                  c.mallProduceVOMap[i][j].id == sessionStorage.getItem("valid")
                ) {
                  c.mallProduceVOMap[i][j] = JSON.parse(
                    sessionStorage.getItem("data")
                  );
                  c.mallProduceVOMap[i][j].count = sessionStorage.getItem(
                    "count"
                  );
                }
              }
            }
          }
        });
        this.heji();
        sessionStorage.removeItem("isok");
      }
    }
    if (sessionStorage.getItem("isok2")) {
      this.chexin = JSON.parse(sessionStorage.getItem("chexin"));
      this.shangjia = JSON.parse(sessionStorage.getItem("shangjia"));
      this.namelist();
      this.onLoad();

      sessionStorage.removeItem("isok2");
    }
    // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
    this.$route.meta.isBack = false;
    //this.isBack = false;
    this.isFirstEnter = false;
  }
};
</script>
<style >
.maintain .van-ellipsis {
  color: #fff !important;
}
.van-dialog .van-button {
  height: 40px;
}
.maintain .van-icon {
  color: #fff !important;
}
.maintain > .van-hairline--bottom::after {
  border: none !important;
}
</style>
<style lang="less" scoped>
body {
  box-sizing: border-box;
}

.liangdeng {
  width: 18px;
  height: 14px;
  vertical-align: middle;
}
.cantianjia {
  float: right;
  color: #2121c0;
  margin-right: 7%;
}
.cankao {
  color: #999;
  overflow: hidden;
  font-size: 12px;
  margin-left: 35px;
  margin-top: 6px;
}
// 底部
.bott {
  height: 55px;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding: 0 12px;
}
.jian {
  border: 1px solid #999;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  position: relative;
}
.c_gen {
  position: absolute;
  right: 0;
  color: #2121c0;
  line-height: 18px;
  // margin-right: 12px;
  bottom: 0;
}
.jian::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  margin-left: -5px;
  margin-top: -1px;
  border-top: 2px solid;
  color: #666;
}
.jia {
  border: 1px solid #999;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  position: relative;
}
.jia::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  margin-left: -5px;
  margin-top: -1px;
  border-top: 2px solid;
  color: #666;
}
.shulian {
  line-height: 20px;
  margin: 0 10px;
}
.jia::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  height: 10px;
  margin-left: -1px;
  margin-top: -5px;
  border-left: 2px solid;
  color: #666;
}
.c_jiage {
  font-size: 16px;
}
.zaixian {
  font-size: 12px;
  line-height: 12px;
}
.bott_a {
  text-align: center;
  margin-top: 5px;
}
.bott_c {
  margin-top: 10px;
  font-size: 14px;
  background: #df3447;
  color: #fff;
  font-weight: bold;
  padding: 7px 18px;
  border-radius: 19px;
}
.heji {
  font-weight: bold;
}
.danwei {
  font-size: 10px;
  color: #d9314c;
}
.monede {
  font-size: 18px;
  font-weight: bold;
  color: #d9314c;
}
.zhe {
  color: #666;
  margin-left: 5px;
}
.cgengen {
  position: absolute;
  top: 0;
  line-height: 40px;
  background: #fff;
  z-index: 99;
  font-size: 15px;
  width: 100%;
  text-align: center;
  font-weight: bold;
}
.bott_b {
  font-size: 13px;
  margin-right: 10px;
  margin-top: 5px;
}

.ccname {
  line-height: 40px;
  font-size: 14px;
  color: #333;
  text-align: center;
  border-bottom: 1px solid #f5f5f5;
}

.anzhuan {
  font-size: 12px;
  color: #666;
  line-height: 12px;
  text-align: right;
}
.van-popup {
  padding-top: 40px;
}
.kefu {
  width: 25px;
  height: 25px;
}
// 底部
// 标题
.biao_ti {
  height: 46px;
  background: #df3447;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 46px;
  text-align: center;
  color: #fff;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.fanhui {
  line-height: 46px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
}
//标题
.beijin_h {
  position: absolute;
  top: 0;
  left: 0;
  // margin-top: 46px;
  height: 90px;
  width: 100%;
  background: #df3447;
  // border-radius: 0 0 10% 10%;
  z-index: 0;
}
.liebiao {
  height: 26px;
  font-size: 12px;
  display: inline-block;
  background: #f5f5f5;
  color: #666;
  border: 1px solid transparent;
  //   padding: 0px 8px;
  margin: 10px 7px 0 7px;
  width: 29%;
  text-align: center;
  line-height: 24px;
  border-radius: 12px;
}
.xuanze {
  background: #ffffff;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  position: relative;
  height: 82px;
  margin: 10px 12px;
  background: #fff;
  z-index: 9;
}
.van-nav-bar {
  background: #df3447;
  color: #fff;
}
.shanpin_lis {
  margin: 0 12px 10px 12px;
  position: relative;
  // background: #fff;

  overflow: hidden;
}
.shanb_biao {
  border-radius: 5px;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 0;
  margin-bottom: 10px;
  background: #fff;
  //   transition: all .8s
}

.shanb_h1 {
  overflow: hidden;
  // line-height: 25px;
}
.jinji {
  font-size: 12px;
  color: #666;
  margin-top: 6px;
  // color: #fff;
  // display: inline-block;
  margin-left: 34px;
  // background: #d9314c;
  line-height: 16px;
  height: 16px;
}
.hantes {
  margin-right: 5px;
}
.hanne {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 75%;
}
.shanb_h2 {
  margin-top: 5px;
  font-size: 12px;
  color: #666;
  height: 16px;
  margin-left: 34px;
  line-height: 16px;
}
.chek {
  font-weight: bold;
  margin-left: 10px;
  margin-top: 3px;
}
.jiner {
  font-weight: 550;
  color: red;
  font-size: 14px;
  margin-right: 10px;
}
.jiner2 {
  color: #999;
  margin-right: 6px;
}
.imgbj {
  margin-right: 10px;
  position: relative;
}
.jia_ding {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
  color: red;
  font-weight: 550;
}
.jiabeijin {
  height: 25px;
  width: 95px;
}
.yhuis {
  color: #fff;
}
.yhuis {
  text-align: center;
  line-height: 14px;
  transform: scale(0.73);
  font-weight: 500;
}

.list_fuwu {
  overflow: hidden;
  position: relative;
  margin-top: 10px;
}
.mian_a {
  width: 70px;
  height: 70px;
  margin-left: 35px;
}
.order_img {
  width: 70px;
}
.mian_b {
  font-size: 14px;
  margin-left: 15px;
  width: 60%;
}
.sscc {
  margin-right: 8px;
  color: #666;
}
.mian_b2 {
  font-size: 14px;
  margin-left: 25px;
  width: 205px;
  margin-top: 18px;
}
.van-stepper__minus,
.van-stepper__plus {
  width: 32px;
  height: 32px;
}
.lis_ha {
  height: 33px;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.lis_hb {
  font-size: 10px;
  line-height: 12px;
  min-height: 10px;
  margin-top: 5px;
}
.lis_hc {
  margin-top: 8px;
  position: relative;
}
.coot {
  overflow: hidden;
  padding-top: 2px;
  // position: absolute;
  // right: 0;
  // margin-right: 15px;
}
.lis_hb span {
  float: left;
  color: #2121c0;
  border: 1px solid #2121c0;
  height: 14px;
  line-height: 13px;
  // margin-left: 5px;
  font-size: 12px;
  transform: scale(0.83);
  border-radius: 3px;
  padding: 0 2px;
}
.jiner_c {
  color: #d9314c;
  width: 35%;
}
.clear {
  clear: both;
  height: 55px;
}
.isActive {
  border: 0.5px solid #df3d48;
  color: #df3d48;
}
.shanb_biao:nth-of-type(n + 2) {
  margin-top: 10px;
}
// .list_fuwu:nth-of-type(n + 2) {
//   margin-top: 10px;
// }
</style>